import React, { useContext, useEffect, useState } from "react";
import LayoutNew from "../../components/shared/LayoutNew";
import { gamesLiveArray } from "../../constraints/constants";
import AuthContext from "../../context/AuthContext";
import { capitalize, isEmpty, upperCase } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const CasinoNew = () => {
  const { launchEGTCasino, user, launchCasino } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const parmas = useParams();
  useEffect(() => {
    if (!isEmpty(parmas)) {
      setData(
        gamesLiveArray?.filter((res) => {
          if (parmas?.key == "live") {
            return res?.cateogry == upperCase(parmas?.type);
          } else if (parmas?.key == "esports") {
            return (
              res?.type == "EGAME" && res?.cateogry == upperCase(parmas?.type)
            );
          } else if (parmas?.key == "fish") {
            return (
              res?.type == "FISHING" && res?.cateogry == upperCase(parmas?.type)
            );
          } else {
            return (
              res?.type == upperCase(parmas?.key) &&
              res?.cateogry == upperCase(parmas?.type)
            );
          }
        })
      );
    }
  }, [parmas]);

  const obj = {
    housebook: "House Book",
    sportBook: "Sportsbook",
    live: "Live Casino",
    slot: "Slot",
    table: "Table",
    esports: "Esports",
    fish: "Fishing",
  };

  return (
    <LayoutNew>
      <div>
        <div>
          <h3>{obj[parmas?.key]}</h3>
          <div className="casino-main">
            {data?.map((res) => {
              return (
                <div
                  onClick={() => {
                    if (!isEmpty(user)) {
                      if (!isEmpty(res?.gameid)) {
                        launchEGTCasino({
                          platForm: res?.platForm,
                          gameType: res?.gameType,
                          casinoType: res?.casinoType,
                          gameid: res?.gameid,
                        });
                      } else {
                        if (
                          res?.platForm !== "" &&
                          res?.gameType !== "" &&
                          res?.casinoType !== ""
                        ) {
                          launchCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameType,
                            casinoType: res?.casinoType,
                          });
                        }
                      }
                    } else {
                      navigate("/login");
                    }
                  }}
                  className="casino-card"
                >
                  <img src={res?.img} />
                  <p>{res?.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </LayoutNew>
  );
};

export default CasinoNew;
