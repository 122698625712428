import React, { useContext, useEffect, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import AuthContext from "../context/AuthContext";
import { HotCasinoData} from "../Utils/constants";
import { Link, useNavigation,useNavigate,useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const AllCasino = () => {
    const { lang, user, launchEGTCasino, launchCasino, withoutLogin } =
    useContext(AuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const parmas = useParams();
    console.log(parmas?.type);
  return (
    <LayoutNew>
      <div>
        
    <div class="games all-casino-page">
   <h5>Hot Game</h5>
        <div class="games-main">
            {parmas?.type=="hot" &&  HotCasinoData.map((item) => {
              return (
                <div  class=""  onClick={() => {
                    if (!isEmpty(user)) {
                      if (item?.gameTypeCheck == "platForm") {
                        launchCasino({
                          platForm: item?.platForm,
                          gameType: item?.gameType,
                          casinoType: item?.casinoType,
                        });
                      } else if (item?.gameType == "egtGame") {
                        launchEGTCasino({
                          platForm: item?.platForm,
                          gameType: item?.gameTypeNew,
                          casinoType: item?.casinoType,
                        });
                      } else if (!isEmpty(item?.gameid)) {
                        launchEGTCasino({
                          platForm: item?.platForm,
                          gameType: item?.gameType,
                          casinoType: item?.casinoType,
                          gameid: item?.gameid,
                        });
                      } else {
                        if (
                          item?.platForm !== "" &&
                          item?.gameType !== "" &&
                          item?.casinoType !== ""
                        ) {
                          launchCasino({
                            platForm: item?.platForm,
                            gameType: item?.gameType,
                            casinoType: item?.casinoType,
                          });
                        }
                      }
                    } else {
                      withoutLogin();
                    }
                  }}>
                <div  class="games-box  ng-trigger ng-trigger-staggerFadeAnimation">
                    <div  class="pic item-ani ">
                        <a  class=""><img  class="" alt="JILI Money Coming" src={item?.icons} loading="lazy" /></a>
                    </div>
                    <div  class="text item-ani ">
                        <h3  class="">{lang == "bn"
                        ? item?.titlebn
                        : item?.title || item?.allOfer}</h3>
                    </div>
                </div>
            </div>

              );
            })}
           
        </div>
    </div>
    
       
      </div>
     
    </LayoutNew>
  );
};

export default AllCasino;
